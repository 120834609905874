import React, { useCallback } from "react";
import useLockTouchBodyScroll from "../../hooks/useLockTouchBodyScroll";
import { StyledGoalDrawer } from "./goalDrawer.style";




const GoalDrawer = ({
  children,
  isOpen,
  onClose
}) => {
  const className = 'goal-drawer';
  useLockTouchBodyScroll(isOpen, className);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    onClose(e);
  }, [onClose]);


  return <>{
    isOpen && <>
    <StyledGoalDrawer
    placement="right"
    visible={isOpen}
    className={className}
    drawerStyle={{
      tranform:'translateX(0)'
    }}
  >
    {children}
  </StyledGoalDrawer>
  </>
  }
  </>;
};

export default React.memo(GoalDrawer);
