import { Select } from "antd";
import styled from "styled-components";





export const GoalSettingContainer = styled.div`
  min-width: 136px;
  padding : 16px;
  display: grid;
  place-items: center;
  background-color: white;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  box-shadow: 0px 7px 4px rgba(0,0,0, 0.03), 0px 1px 2px rgba(0,0,0, 0.05), 0px 0px 0px rgba(0,0,0, 0.05);
  transition: box-shadow 700ms ease-in-out;
  &:hover{
  box-shadow: 0px 7px 4px rgba(0,0,0,0.06),0px 1px 2px rgba(0,0,0,0.10),0px 0px 0px rgba(0,0,0,0.10);
  }

  @media screen and (max-width: 834px){
  min-width: unset;
  padding: 8px;
  max-width: 30vw;
  }
  `;
export const GoalDifficultyContainer = styled(GoalSettingContainer)`
  .difficulty{
  height: 0;
  min-width: 96px;
  visibility: hidden;
  }
`;
export const GoalTypeContainer = styled(GoalSettingContainer)``;

export const GoalSettingsTypoContainer = styled.div`
  display: grid;
  gap: 4px;
  place-items: center;

  `;

export const GoalSettingsSelect = styled(Select)`

  `;

export const GoalDifficultySelect = styled(GoalSettingsSelect)`
  `;
export const GoalDifficultyTypoContainer = styled(GoalSettingsTypoContainer)``;

export const GoalDifficultyOptionContainer = styled.div`
  margin: 8px 4px;
  display: grid;
  place-items: center;
  gap: 8px;
  `;
