import styled, { css } from "styled-components";
import { GoalActionContainer } from "../goalCard.style";

export const ActionStatusContainer = styled.div`
  ${css`
      display: grid;
      grid-template-columns: 0fr 0fr 1fr;
      gap: 8px;
      place-content: center;
      align-items: center;
      text-wrap: nowrap;
      margin-top: 8px;
      svg{
          margin-top: 0px;
      }
    `}
  `;
export const ActionDifficultyContainer = styled.div`
  ${css`
      display:grid;
    `}`;
export const ActionTextContainer = styled.div`
  ${css`
    display : grid;
    gap: 4px;
    width:100%;
    div:not(nth-child(3)) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    `}
  `;
export const ActionContainer = styled(GoalActionContainer)`
  ${css`
      cursor: pointer;
      gap: 8px;
      transition : filter 0.5s ease-in-out;
      &:not(:first-of-type), &:not(:nth-of-type(2)){
          margin-top: 0px;
      }
      padding: 20px 16px 4px;
      grid-template-columns: 1fr 0fr ;
      ${(props) => props.highlightCommonGoal && css`
         filter: drop-shadow(var(--accent-color) 1px 1px 6px );
        `}
      & > div:first-of-type{
      height: unset;
      position: relative;
      bottom: 6px;
      place-self: center;
      svg {
          width: 32px;
      }
      }
    `}
  `;
export const ActionListContainer = styled.div`
  ${css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
      @media screen and (max-width: 834px){
          grid-template-columns: 1fr;
      }
    `}
  `;

export const ActionFormDifficultyStatusContainer = styled.div`
  ${css`
      margin-top: 42px;
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr 1fr;
    `}
  `;
export const ActionFormContentContainer = styled.div`
 ${css`
 display: grid;
.ant-calendar-picker{
    max-width: 280px;
    place-self: center;
}
.goal-field-note {
    margin-top: 16px;
}
   `}
  `;
