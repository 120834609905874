import styled, { css } from "styled-components";

export const GoalCardContainer = styled.div`
  border-radius: 8px;
  display : grid;
  grid-template-rows: repeat(2, 1fr) 0fr 8px;
  gap: 0;
  background: white;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(162, 155, 164, 0.18), 0px 2px 6px rgba(162, 155, 164, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
  opacity: 1;
  scale: 1;
  transition: scale 0.5s ease-in-out, opacity 0.5s ease-in-out ,filter 0.5s ease-in-out;
  cursor: pointer;
  ${(props) => props.hasMaxHeight && css`
      max-height: 224px;
    `}
  ${(props) => props.highlightCommonGoal && css`
     filter: drop-shadow(var(--accent-color) 1px 1px 12px );

    `}
  `;
export const LoadingGoalCard = styled.div`
  border-radius: 8px;
  height: 160px;
  @media screen and (max-width : 834px){
  max-width: 100%;
  }
  `;
export const GoalIconCtaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0px 16px;
  `
;
export const GoalDrawerDataContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap:8px;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 834px){
      grid-template-columns: max(30vw) max(30vw) max(30vw);
  }
  `;
export const GoalDrawerFormContentContainer = styled.div`
  display: grid;
  .ant-calendar-picker{
      max-width: 280px;
      place-self: center;
  }
  .goal-field-title {
    font-size: 18px;
    line-height: 120%;
    box-shadow: 0px 7px 4px rgba(0,0,0,0.03),0px 1px 2px rgba(0,0,0,0.05),0px 0px 0px rgba(0,0,0,0.05);
    background: white;


    -webkit-letter-spacing: -3%;
    -moz-letter-spacing: -3%;
    -ms-letter-spacing: -3%;
    letter-spacing: -0.03em;
  }
  `
export const GoalDescContainer = styled.div`
  text-wrap: pretty;
  text-align : left;
  margin-bottom:8px;
  padding: 0 16px;
  height: 40px;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -3%;
  font-weight: 500;
  font-family:Work Sans;

  ${(props) => props.isCompleted && css`
          text-decoration: line-through;
          color: grey;
    `}

  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  `;
export const GoalCTAInfo = styled.div`
  display : grid;
  grid-template-columns: repeat(3, 0fr) 1fr;
  gap: 8px;
  align-items: center;
  background: #FCFAFB;
  text-wrap: nowrap;
  padding: 6px 16px ;
  icon:last-of-type {
    justify-self: end;
  }
  `;
export const GoalCardStatus = styled.div`
  border-radius: 16px;
  text-wrap: nowrap;
  font-size: 12px;
  padding: 6px 8px;
  cursor: pointer;
  background-color: #F8F4EE;
  border-color: #A29BA4;
  color: #A29BA4;
  ${(props) => props.status ==='active' ?
  css`
    background-color: #2394BC;
    border-color: #2394BC;
    color: white;
    ` : props.status ==='completed' ?
  css`
    background-color: #08B556;
    border-color: #08B556;
    color: white;
    ` : props.status ==='stuck' ?
  css`
    background-color: #BD273A;
    border-color: #BD273A;
    color: white;
    ` : props.status === 'not-started' &&
  css`
      background-color: #DD9E4A;
      border-color: #DD9E4A;
      color: black;
    `

  }
  `;
export const GoalEmptyContainer = styled.div`
  display: grid;
  place-items: center;
  grid-area: 1 / 2;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  gap: 16px;
  margin-bottom: 32px;
  @media screen and (max-width: 834px){
    grid-area: unset;
  }

  `;
export const GoalDrawerActions = styled.div`
  display: grid;
  gap: 8px;

  `;
export const GoalActionsEmpty = styled.div`
  display : grid;
  margin-top: 8px;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  background: white;
  cursor:pointer;
  place-items: center;
  box-shadow: 0px 7px 4px rgba(0,0,0,0.03),0px 1px 2px rgba(0,0,0,0.05),0px 0px 0px rgba(0,0,0,0.05);
  `;
export const GoalActionContainer = styled.div`
  ${
  css`
      display: grid;
      grid-template-columns: 1fr 0fr ;
      gap: 8px;
      border-radius: 8px;
      background: white;
      margin-top:16px;
      padding: 16px;
      & > div:first-of-type{
      // height: 96px;
      width: 100%;
      place-self: center;
      }
      .action-title{
      height: 45px;
      margin-top: 0px;
      padding-top: 6px;
      padding-bottom: 6px;
      }
      .action-note {
      height: 80px;
      margin-top: 8px;

      }
      svg{
      cursor: pointer;
      margin-top: 4px;
      padding-left: 4px;
      transition : color 500ms ease-in-out;
      &:hover{
      color: #FC6453;
      }
    }
      }



      box-shadow: 0px 7px 4px rgba(0,0,0,0.03),0px 1px 2px rgba(0,0,0,0.05),0px 0px 0px rgba(0,0,0,0.05);

      @media screen and (max-width: 834px){
          padding : 16px 4px;
      }
    `
  }

  `;
export const GoalActionTextContainer = styled.div`
  display: grid;
  .action-title{
    margin-top : 8px;
  }
  .action-title, .action-note{
  box-shadow: 0px 7px 4px rgba(0,0,0,0.03),0px 1px 2px rgba(0,0,0,0.05),0px 0px 0px rgba(0,0,0,0.05);
  background: rgb(250 250 250 / 45%);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: box-shadow 700ms ease-in-out;
  &:hover{
  box-shadow: 0px 7px 4px rgba(0,0,0,0.06),0px 1px 2px rgba(0,0,0,0.10),0px 0px 0px rgba(0,0,0,0.10);
  }
  }
  `;
export const GoalPopoverContainer = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;

  `;
export const GoalActionStatusDifficultyContainer = styled.div`
  ${css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 4px;
    `}
  `;
