import styled, { css } from "styled-components";


export const StyledGoalDrawer = styled.div`
  ${css`
      &:focus-visible{
      outline: none;
      }
      position: fixed;
      z-index: 1000;
      inset: 74px 0px 0px auto;
      width: fit-content;
      border-radius: 12px 0 0 12px;
      box-shadow: -6px 1px 4px rgba(0, 0, 0, 0.03), -1px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05);
      background: #f9f7f3e6;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      padding: 40px 56px;
      overflow-y: scroll;
      .ant-drawer-content-wrapper{
         transform: translateX(0%) !important;
      }
    @media screen and (max-width: 834px) {
      inset: 80px 0 0 0;
      border-radius : 8px  ;
      padding: 16px 8px;
      width: 100%;
    }
    /* .ant-drawer-mask{
      position: absolute;
      z-index: 0;
      inset:0;
      right: 100%;
      left: calc(-1 *(100vw - 100%));
      background-color: rgba(0,0,0,0.2);
      backdrop-filter: blur(2px);
    -webkit-backdrop-filter:blur(2px);
    }
      .ant-drawer-content-wrapper{
      width: 100% !important;
      .ant-drawer-content {
          background: transparent;
    .ant-drawer-header-no-title{
      display: grid;
      grid-auto-flow: column;
      justify-content: end;
    }
          .ant-drawer-close {
              right: 10px;
              background: none;
              border:none;
              .anticon-close {
              cursor: pointer;
                  border: 1px solid #C1C1C180;
                  border-radius: 50%;
                  padding: 3px;
                  display: grid;
                  width: 27px;
                  place-items: center;
                  height: 27px;
                  svg {
                      height: 15px;
                      width: 15px;
                      fill: #675F72;

                  }
              }
          }
      } */
    `}
  `;
export const GoalDrawerClose = styled.span`
      width: fit-content;
      height: fit-content;
      display: grid;
      place-content: center;
      position: sticky;
      cursor: pointer;
      place-self: end;
      // top: 88px;
      right: 0;
      z-index: 1000;
      background: #f9f7f3c4;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border-radius: 32px;
      @media screen and (max-width: 834px) {
      right: 16px;

      }
      `;
export const GoalDrawerBckd = styled.div`
  display: none;
  ${(props) => props.visible && css`
    display: unset;
    `}
  z-index: 1;
  content:'';
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(16px);
  `;
export const GoalCreateDrawerContent = styled.div`
  display: grid;
  .goal-field-title, .goal-field-note{
  height: 80px;
  margin-bottom: 24px;
  color: black;
  box-shadow: 0px 7px 4px rgba(0,0,0,0.03),0px 1px 2px rgba(0,0,0,0.05),0px 0px 0px rgba(0,0,0,0.05);
  background: white;
  transition: box-shadow 700ms ease-in-out;
  &:hover{
  box-shadow: 0px 7px 4px rgba(0,0,0,0.06),0px 1px 2px rgba(0,0,0,0.10),0px 0px 0px rgba(0,0,0,0.10);
  }
  }
  .goal-field-note{
  height: 124px;

  margin-top: 0;
  }
  `;
