/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { mediaMax } from '../../styles/mixins/media';

export const Typography = styled.div`
  color: ${props => props.color || '#675f72'};
  font-size: ${props => props.fz || '14px'};
  font-weight: ${props => props.fw || '400'};
  line-height: ${props => props.lh || '1'};
  letter-spacing: ${props => props.lt || '-0.02em'};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => (props.uppercase && 'uppercase') || props.textTrans};
  white-space: ${props => props.whiteSpace};
  word-break: ${props => props.wordBreak};
  text-wrap: ${props => props.textWrap};
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
  margin-right: ${props => props.mr};
  margin-left: ${props => props.ml};
  min-height: ${props => props.minHeight};
  text-shadow: ${props => props.textShadow};
  padding: ${props => props.padding};
  background-color: ${props => props.backgroundColor};
  cursor: ${props => props.cursor};
  ${(props) => props.clamp && css`
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${props.lineClamp ? props.lineClamp : 2};
    -webkit-box-orient: vertical;
    `}
  ${props =>
    props.secondaryFont &&
    `
    font-family: 'IBM Plex Serif', 'Work Sans', sans-serif;
  `}

  ${mediaMax.md`
    margin-left: ${props => props.mlMd};
  `}

  ${mediaMax.sm`
    font-size: ${props => props.fzSm};
    font-weight: ${props => props.fwSm};
    line-height: ${props => props.lhSm};
    letter-spacing: ${props => props.ltSm};

    margin-top: ${props => props.mtSm};
    margin-bottom: ${props => props.mbSm};
    margin-left: ${props => props.mlSm};

    ${props =>
      props.centerSm &&
      `
      text-align: center;
    `}
    padding: ${props => props.paddingSm};
  `}

  ${props =>
    props.primary &&
    `
    color: #FC6453;
  `}

  ${props =>
    props.center &&
    `
    text-align: center;
  `}

  ${props =>
    props.inline &&
    `
    display: inline;
  `}

  ${props =>
    props.bigFirstLetter &&
    `
    &:first-letter {
      text-transform: uppercase;
    }
    `}
`;
