import styled from 'styled-components';
import features from '../../styles/mixins/features';
import media, { mediaMax } from '../../styles/mixins/media';

export default styled.div`
  display: inline-block;
  text-shadow: 1px 1px 3px #ccd0d4, 0 0 0 rgba(0, 0, 0, 0.8), 1px 1px 4px #fff;

  ${(props) =>
    props.styles === 'congrats' &&
    `
      width: 100%;
      margin-top: 16px;
      margin-bottom: 24px;
      ${media.md`
        width: auto;
      `}
  `}

  ${(props) =>
    props.styles === 'opinion-program-send' &&
    `
      width: 100%;
      padding: 0 11px;
      ${media.md`
        width: auto;
        min-width: 140px;
      `}
      ${media.lg`
        width: auto;
      `}
  `}

  ${media.md`
  ${(props) =>
    props.styles === 'opinion-program-send' &&
    `width: auto;
    min-width: 140px;`}
      `}

  > a,
  > button {
    display: flex;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: -0.02em;

    span {
      margin-left: 12px;
    }

    &:disabled {
      opacity: 0.3;
    }

    ${(props) =>
      props.icon &&
      `
        font-size: 16px !important;
    `}

    ${(props) =>
      props.textColor &&
      `
        color: ${props.theme.colors[props.textColor]} !important;
    `}

    ${(props) =>
      props.styles === 'primary' &&
      `
        border: 2px solid ${props.color || props.theme.colors.persimmon};
        background-color: ${props.color || props.theme.colors.persimmon};
        color: #fff;

        &:active,
        &:visited {
          color: #fff;
        }
    `}

    ${(props) =>
      props.styles === 'primary-transparent' &&
      `
        border: none;
        background-color: transparent;
        color: ${props.theme.colors.martinique};

        &:active,
        &:visited {
          color: ${props.theme.colors.martinique};
        }
    `}

    ${(props) =>
      props.styles === 'secondary' &&
      `
        border: 2px solid ${props.theme.colors.martinique};
        background-color: rgba(248, 244, 238, 0.8);
        color: ${props.theme.colors.martinique};

        &:active,
        &:visited {
          color: ${props.theme.colors.martinique};
        }
    `}

    ${(props) =>
      props.styles === 'secondary-grey' &&
      `
        border: 1px solid rgba(230, 226, 222, 0.7);
        background-color: ${props.theme.colors.pampas2};
        color: ${props.theme.colors.persimmon};
        text-transform: initial;
        font-weight: 400 !important;

        &:active,
        &:visited {
          color: ${props.theme.colors.persimmon};
        }
    `}

    ${(props) =>
      props.styles === 'secondary-grey-back' &&
      `
        border: 2px solid rgba(230, 226, 222, 0.7);
        background-color: ${props.theme.colors.pampas2};
        color: ${props.theme.colors.mamba};
        text-transform: initial;
        font-weight: 500 !important;
        &:active,
        &:visited {
          color: ${props.theme.colors.persimmon};
        }
    `}

    ${features.notouch`
      ${(props) =>
        props.styles === 'primary' &&
        `
        &:hover {
          color: ${props.theme.colors.athensGray};
        }
      `};

      ${(props) =>
        props.styles === 'secondary' &&
        `
        &:hover {
          color: ${props.theme.colors.martinique};
        }
      `};
    `};

    ${(props) =>
      props.size === 'small' &&
      `
        font-size: 11px;
        font-weight: 500;
        padding-top: 6px;
        padding-right: 10px;
        padding-bottom: 6px;
        padding-left: 10px;
    `};
    ${(props) =>
      props.size === 'medium' &&
      `
        font-size: ${props.theme.typography.browserContext};
        font-weight: 500;
        padding-top: 10px;
        padding-right: 12px;
        padding-bottom: 10px;
        padding-left: 12px;
    `};

    ${(props) =>
      props.size === 'in-group-select' &&
      `
        font-size: ${props.theme.typography.browserContext};
        font-weight: 600;
        padding: 12px 57px;
    `};

    ${(props) =>
      props.size === 'large' &&
      `
        font-size: ${props.theme.typography.browserContext};
        font-weight: 600;
        padding-top: 10px;
        padding-right: 22px;
        padding-bottom: 10px;
        padding-left: 22px;
    `};

    ${(props) =>
      props.size === 'xlarge' &&
      `
        font-size: ${props.theme.typography.browserContext};
        font-weight: 500;
        padding-top: 10px;
        padding-right: 32.5px;
        padding-bottom: 10px;
        padding-left: 32.5px;
    `};
    ${(props) =>
      props.size === 'big' &&
      `
      font-size: ${props.theme.typography.browserContext};
      font-weight: 500;
      padding-top: 18px;
      padding-right: 12px;
      padding-bottom: 18px;
      padding-left: 12px;
   `};
    ${(props) =>
      props.size === 'xbig' &&
      `
      border: 1px solid ${props.color || props.theme.colors.persimmon};
      padding: 12px 35.5px;
      font-weight: 600;
      line-height: 19px;
    `};
    ${(props) =>
      props.size === 'tinyArrow' &&
      `
        position: relative;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        padding-top: 4px;
        padding-right: 26px;
        padding-bottom: 4px;
        padding-left: 10px;
    `};

    ${media.lg`
      ${(props) =>
        props.size === 'small' &&
        `
        font-size: 13px;
        padding-top: 8px;
        padding-right: 12px;
        padding-bottom: 8px;
        padding-left: 12px;
    `};

      ${(props) =>
        props.size === 'large' &&
        `
        font-size: 18px;
        padding-top: 12px;
        padding-right: 26px;
        padding-bottom: 12px;
        padding-left: 26px;
    `};

     ${(props) =>
       props.size === 'xlarge' &&
       `
        font-size: 18px;
        padding-top: 12px;
        padding-right: 38px;
        padding-bottom: 12px;
        padding-left: 38px;
    `};
    ${(props) =>
      props.size === 'big' &&
      `
      font-size: ${props.theme.typography.browserContext};
      font-weight: 500;
      padding-top: 18px;
      padding-right: 12px;
      padding-bottom: 18px;
      padding-left: 12px;
   `};
     ${(props) =>
       props.size === 'tinyArrow' &&
       `
        position: relative;
        line-height: 16px;
        padding-top: 4px;
        padding-right: 26px;
        padding-bottom: 4px;
        padding-left: 10px;
    `};
  `};

    ${(props) =>
      (props.styles === 'favorite-sessions' || props.styles === 'congrats') &&
      `
      background: #FFFFFF;
      border-radius: 4px;
      border: none;
      color: #675F72;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 125%;
      padding-top: 13px;
      padding-right: 16px;
      padding-bottom: 13px;
      padding-left: 21px;
      opacity: 1!important;

      svg {
        margin-right: 15px;
        stroke-width: 1.5;
        path {
          stroke: #675F72;
        }
      }

      .isFavorite {
        fill: #675F72;
      }
    `}
    ${(props) =>
      props.styles === 'congrats' &&
      `
        text-transform: none;
    `}

    ${(props) =>
      props.styles === 'msg-sessions' &&
      `
      background: #FC6353;
      border-radius: 4px;
      border: none;
      color: #FFF;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      padding-top: 11px;
      padding-right: 16px;
      padding-bottom: 11px;
      padding-left: 18px;

      svg {
        margin-right: 10px;
      }
    `}
    ${(props) =>
      (props.styles === 'opinion-session' || props.styles === 'opinion-article') &&
      `
      background: #FC6353;
      border-radius: 4px;
      border: none;
      color: #FFF;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      padding-top: 11px;
      padding-right: 16px;
      padding-bottom: 11px;
      padding-left: 18px;
      padding: 12px 32px;
      justify-content: center;

      svg {
        margin-right: 10px;
        path{
          color: #FFFFFF;
        }
      }

    `}


    ${(props) =>
      (props.styles === 'opinion-session-send' || props.styles === 'opinion-article-send' || props.styles === 'opinion-program-send') &&
      `
      background: #FC6353;
      border-radius: 4px;
      border: none;
      color: #FFF;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      padding: 12px 32px;
      margin-top: 40px;
      margin-bottom: 72px;
      justify-content: center;
      svg {
        path{
          fill: #FFF;
        }
      }
    `}
    ${(props) => props.margin && `margin-left: 16px;`}

    ${(props) =>
      (props.styles === 'opinion-article' || props.styles === 'opinion-article-send') &&
      `
      color: #675F72;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
      svg {
        path{
          fill: #675F72;
        }
      }
    `}

    ${(props) =>
      props.styles === 'opinion-article-send' &&
      `
        background: #FC6353;
        color: #FFFFFF;
        width:100%;
        max-width: 400px;
        min-width: 200px;

        svg {
          path{
            fill: #ffffff;
          }
        }

        @media (min-width: 466px) {
          max-width: 272px;
        }
    `}

    ${(props) =>
      props.styles === 'opinion-program-send' &&
      `
      margin: 0;
      width: 100%;
      ${media.md`
        width: auto;
      `}
    `}


    ${(props) =>
      props.styles === 'transparent-gray' &&
      `
        border: 1px solid #E5E2DE;
        background-color: transparent;
        font-size: 16px;
        line-height: 125%;
        font-weight: normal;
        color: #675F72;
        text-transform: initial;
        letter-spacing: -0.02em;
        margin-left: 24px;
        text-transform: uppercase;
    `}

    ${(props) =>
      props.styles === 'white-shadow' &&
      `
        box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
        border: none;
        border-radius: 8px;
        background: #FFFFFF;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        color: #675F72;
        text-transform: initial;
        letter-spacing: -0.02em;
        padding: 14px 12px;
        transition: background 0.3s ease-in-out,color 0.3s ease-in-out;
        &:hover {
        background: rgb(252, 100, 83);
        color: black;
        }
    `}

    ${(props) =>
      props.styles === 'white-shadow-program' &&
      `
        border: 1px solid #E6E2DE;
        background: #FFFFFF;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        color: #675F72;
        text-transform: initial;
        letter-spacing: -0.02em;
        padding: 10px 16px;
        width: 130px;
        height: 44px;
        text-transform: uppercase;
        justify-content: center;
        svg{
          margin-right: 8px;
        }
    `}

    ${mediaMax.md`
      ${(props) =>
        props.styles === 'congrats' &&
        `
        height: 44px;
        width: 100%;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 125%;
        /* identical to box height, or 20px */

        text-align: center;
        letter-spacing: -0.02em;

        color: #675F72;


        /* Inside Auto Layout */

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        text-transform: none;
          svg {
            margin-bottom: 7px;
            margin-right: 12px;
            height: 19px;
            width: 19px;
          }
      `}
    `}
    ${mediaMax.sm`
      ${(props) =>
        props.styles === 'favorite-sessions' &&
        `
          flex-direction: column;
          font-weight: 600;
          font-size: 10px;
          line-height: 125%;
          width: 100%;
          padding-bottom: 10px;

          svg {
            margin-bottom: 7px;
            margin-right: 0;
            height: 19px;
            width: 19px;
          }
      `}
      ${(props) =>
        props.styles === 'congrats' &&
        `
          line-height: 125%;
          width: 100%;
          padding-bottom: 10px;
          text-transform: none;
          svg {
            height: 19px;
            width: 19px;
          }
      `}
      ${(props) =>
        props.styles === 'msg-sessions' &&
        `
          flex-direction: column;
          font-weight: 600;
          font-size: 10px;
          line-height: 125%;
          width: 100%;
          padding-bottom: 10px;

          svg {
            margin-bottom: 7px;
            margin-right: 0;
            height: 19px;
            width: 19px;
          }
      `}
      ${(props) =>
        props.styles === 'transparent-gray' &&
        `
        padding: 12px 18px;
        width: 100%;
        margin-left: auto;
      `}
      ${(props) =>
        props.styles === 'white-shadow' &&
        `
          box-shadow:rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
          background: #FFFFFF;
          font-size: 14px;
          line-height: 16px;
          font-weight: normal;
          color: #675F72;
          text-transform: initial;
          letter-spacing: -0.02em;
          padding: 14px 12px;
      `}
      ${(props) =>
        props.size === 'xbig' &&
        `
        padding: 12px 18px;
        width: 100%;
      `}

      ${(props) =>
        props.styles === 'opinion-session-send' &&
        `
        width: 100%;
        margin-top: 8px;
        margin-bottom: 0;
      `}
    `}
  }
  ${mediaMax.sm`
    ${(props) =>
      props.styles === 'opinion-session-send' &&
      `
      width: 100%;
      margin-top: 8px;
      margin-bottom: 0;
    `}
  `}
  &.leave-calendar{
      button{
          color: white  !important;
          background-color: var(--accent-color) !important;
          border:none !important;
          font-size: 1rem;
      }
  }
`;
