import { useField } from "formik";
import { Calendar02Icon } from "hugeicons-react";
import moment from "moment";
import { useCallback, useState } from "react";
import { StyledDatePicker } from "../../components/Form/DatePicker/formikDatePicker.style";
import { Typography } from "../../stories/Typography";
import { GoalDateContainer, GoalDateTypoContainer } from "./goalDate.style";


const GoalDate = ({goalDate, disabledDate, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const [showDatePicker, setShowDatepicker] = useState(false);
  const [dispalyedDate, setDisplayedDate] = useState(goalDate ? moment(goalDate).format('DD/MM/YYYY').toString() : null);
  const handleCompletionDateChange = useCallback((e) => {
    e.stopPropagation();
    setShowDatepicker(previousShowDatePicker => !previousShowDatePicker);
  }, []);
  const handleChange = useCallback((value) => {
    setDisplayedDate(moment(value).format('DD/MM/YYYY').toString());
    const formattedValue = moment(value).format('YYYY-MM-DD');
    setValue(formattedValue);
  }, [setValue]);

  return <GoalDateContainer  onClick={(e) => handleCompletionDateChange(e)}  >
  <Calendar02Icon/>
  <GoalDateTypoContainer>
  <Typography color={dispalyedDate ? "black" : "#8A8390"} fz="16px" fw="500" lh="110%" ls="-2%">
    {dispalyedDate ?? 'DEFINE'}
  </Typography>
  <Typography color="#8A8390" fz="12px" fw="500" lh="90%" ls="-2%">
  DATE
  </Typography>
  </GoalDateTypoContainer>
    <StyledDatePicker
    value={field.value ? moment(field.value) : null}
    format="DD/MM/YYYY"
    className={'goal-date'}
    disabledDate={disabledDate}
    open={showDatePicker ?? null}
    onClick={handleCompletionDateChange}
    showToday
    onChange={handleChange}
    onBlur={field.onBlur}
    />
  </GoalDateContainer>;
}


export default GoalDate;
