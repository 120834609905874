import { Field, FieldArray, useFormikContext } from "formik";
import { Delete02Icon, PlusSignSquareIcon } from "hugeicons-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteUserGoalActionsMutation } from "../../api/apiSlice";
import Button from "../../components/Button";
import { TextAreaField } from "../../components/Form/styles";
import { Typography } from "../../stories/Typography";
import { GoalActionContainer, GoalActionsEmpty, GoalActionStatusDifficultyContainer, GoalActionTextContainer, GoalDrawerActions, GoalDrawerDataContainer, GoalDrawerFormContentContainer } from "./goalCard.style";
import GoalDate from "./goalDate";
import GoalDifficulty from "./goalDifficulty";
import GoalStatus from "./goalStatus";




const GoalDrawerFormContent = ({
  goal,
  handleDeleteGoalAction
}) => {
  const { t } = useTranslation();

  const { values, isSubmitting, isValid } = useFormikContext();



  const emptyAction = {
    contextType: goal != null ? 'goal' : 'user',
    contextId: goal?.id != null ? Number(goal?.id) : 0,
    title: '',
    note: '',
    status: 'not-started',
    motivationLevel: 4,
    completionDate: null
  };
  const handleActionRemoval = useCallback((arrayHelpers,actionIndex, actionId, goalActionId) => {
    arrayHelpers.remove(actionIndex);
    if(goalActionId != null ){
      handleDeleteGoalAction(goalActionId);
    }
  }, [handleDeleteGoalAction]);
  return <GoalDrawerFormContentContainer>
  <Typography fz="22px" fw="500" mb="8px" ls={'-0.03em'}>
    {t('your_goal')}
  </Typography>
  <TextAreaField
    component="textarea"
    type="text"
    name="goal.title"
    autoComplete="goal.title"
    className="goal-field-title"
    placeholder={t('goal_name_placeholder')}
  />
  <GoalDrawerDataContainer>
    <Field as={GoalStatus} initialStatus={goal?.status} goalId={goal?.id} name={'goal.status'} />
    <Field as={GoalDifficulty} motivationLevel={goal?.motivationLevel} goalId={goal?.id} name={'goal.motivationLevel'} />
    <Field as={GoalDate}  goalDate={ goal?.completionDate} name={"goal.completionDate"} />
  </GoalDrawerDataContainer>
    <Typography fz="22px" fw="500"  mt="24px" ls={'-0.03em'}>
      {t('your_action')}
    </Typography>
  <FieldArray name='actions' render={arrayHelpers => (
    <GoalDrawerActions>
      {
        values?.actions?.map((action, index) => {
          return <GoalActionContainer key={index}>
          <GoalActionTextContainer>
          <GoalActionStatusDifficultyContainer>
          <Field name={`actions.${index}.status`} as={ GoalStatus} initialStatus={action?.status} goalId={action?.id} />
          <Field name={`actions.${index}.motivationLevel`} as={ GoalDifficulty} motivationLevel={action?.motivationLevel} goalId={action?.id} />
          <Field name={`actions.${index}.completionDate`} as={GoalDate}  goalDate={ action?.completionDate}  />

          </GoalActionStatusDifficultyContainer>
          <TextAreaField
          name={`actions.${index}.title`}
          component="textarea"
          type="text"
          className={'action-title'}
          placeholder={t('action_name_placeholder')}/>
          <TextAreaField
          name={`actions.${index}.note`}
          component="textarea"
          type="text"
          className={'action-note'}
          placeholder={t('action_note_placeholder')}/>

          </GoalActionTextContainer>
          <Delete02Icon onClick={() => handleActionRemoval(arrayHelpers, index, action.id, action?.goalActionId)}/>
          </GoalActionContainer>
        })}
          <GoalActionsEmpty onClick={() => arrayHelpers.push(emptyAction)}>
          <PlusSignSquareIcon />

            <Typography>
              Add new actions to complete your goal
            </Typography>
          </GoalActionsEmpty>
    </GoalDrawerActions>
  )
  } />
 <Button  type="submit"
 size="large"
 disabled={isSubmitting || !isValid}
 style={{
   justifySelf:'center',
   display:'grid',
   justifyContent: 'center',
   width: '100%',
   marginTop: '24px',
   textTransform: 'uppercase',
   color: '#fff',
 }}
    > {t('save_selection')}</Button>
  </GoalDrawerFormContentContainer>;
}

export default GoalDrawerFormContent;
