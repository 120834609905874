import { useEffect, useRef } from 'react';

let activeComponentsCount = 0;
const activeClassNames = new Set<string>();

const useLockTouchBodyScroll = (open: boolean, className?: string) => {
  const hasAddedListener = useRef(false);

  useEffect(() => {
    const preventBodyScroll = (event: Event) => {
      if (
        !event.composedPath().some((node) => {
          if (node instanceof Element) {
            if (node.classList.contains(className || '')) return true;
            if (
              Array.from(node.children).some((child) => child.classList.contains(className || ''))
            )
              return true;
          }
          return false;
        })
      ) {
        event.preventDefault();
      }
    };

    const allowModalScroll = (event: TouchEvent) => {
      event.stopPropagation();
    };

    let cleanupModalListeners = () => {
      void 0;
    };

    if (open) {
      if (activeComponentsCount === 0) {
        document.body.addEventListener('touchmove', preventBodyScroll, { passive: false });
        document.body.addEventListener('scroll', preventBodyScroll, { passive: false });
      }
      activeComponentsCount += 1;

      if (className) {
        const modalElements = document.getElementsByClassName(className);

        Array.from(modalElements).forEach((element) => {
          const htmlElement = element as HTMLElement;
          htmlElement.addEventListener('touchmove', allowModalScroll, { passive: false });
        });

        hasAddedListener.current = true;
        activeClassNames.add(className);

        cleanupModalListeners = () => {
          if (hasAddedListener.current && !activeClassNames.has(className)) {
            Array.from(modalElements).forEach((element) => {
              const htmlElement = element as HTMLElement;
              htmlElement.removeEventListener('touchmove', allowModalScroll);
            });
          }
        };
      }
    } else {
      activeComponentsCount -= 1;

      if (activeComponentsCount === 0) {
        document.body.removeEventListener('touchmove', preventBodyScroll);
      }

      if (className && hasAddedListener.current) {
        activeClassNames.delete(className);
      }
    }

    return () => {
      if (activeComponentsCount === 0) {
        document.body.removeEventListener('touchmove', preventBodyScroll);
      }
      cleanupModalListeners();
    };
  }, [open, className]);
};

export default useLockTouchBodyScroll;
