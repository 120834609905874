import styled from "styled-components";
import { GoalSettingContainer, GoalSettingsTypoContainer } from "./goalDIfficulty.style";



export const GoalDateContainer = styled(GoalSettingContainer)`
  .ant-calendar-picker  {
  &.goal-date{
      height: 0;
      margin: 0;
      input,i{
      display: none;
      }
  }  }
  `;
export const GoalDateTypeContainer = styled(GoalSettingContainer)``

export const GoalDateTypoContainer = styled(GoalSettingsTypoContainer)``;
