import { Popover } from 'antd';
import { Form, Formik } from 'formik';
import { CancelCircleIcon, Delete02Icon } from 'hugeicons-react';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateUserActionMutation, useCreateUserGoalActionsMutation, useDeleteGoalMutation, useDeleteUserGoalActionsMutation, useGetUserProfileQuery, useUpdateGoalMutation, useUpdateUserActionMutation, useUpdateUserGoalActionsMutation } from '../../api/apiSlice';
import { ReactComponent as Menu } from '../../images/more-2.svg';

import SVGBucketStatus from './goal-icons/bucket';
import SVGCactusStatus from './goal-icons/cactus';
import SVGCakeStatus from './goal-icons/cake';
import SVGMeditateStatus from './goal-icons/meditate';
import SVGMontainStatus from './goal-icons/montain';
import {
  GoalCardContainer,
  GoalCardStatus,
  GoalCTAInfo,
  GoalDescContainer, GoalIconCtaContainer,
  GoalPopoverContainer
} from './goalCard.style';
import GoalDrawer from './goalDrawer';
import { GoalCreateDrawerContent, GoalDrawerBckd, GoalDrawerClose } from './goalDrawer.style';
import GoalDrawerFormContent from './goalDrawerFormContent';

const transformInitialValues = (data) => {
  return {
    goal: data?.goal,
    actions: data?.actions.flat().map(action => {
      return {...action?.attributes, goalActionId: action.goalActionId}
    })
  };
};

const GoalCard = ({ goal, hasMaxHeight, userGoalActionId, actions, onDelete,onUpdateGoal,highlightCommonGoal, ...props }) => {
  const { t } = useTranslation();

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [updateGoal] = useUpdateGoalMutation();
  const [createUserGoalAction] = useCreateUserGoalActionsMutation();
  const [createUserAction] = useCreateUserActionMutation();
  const [updateUserAction] = useUpdateUserActionMutation();
  const [updateUserGoalAction] = useUpdateUserGoalActionsMutation();

  const [deleteGoalAction] = useDeleteUserGoalActionsMutation();
  const [deleteGoal] = useDeleteGoalMutation();
  const {data: user} = useGetUserProfileQuery();

  const goalFormValues = useMemo(() => {
    return transformInitialValues({
    goal: {
      id: goal.id ?? '',
      title: goal.title ?? '',
      description: goal.description ?? '',
      status: goal.status ?? '',
      motivationLevel: goal.motivationLevel ?? 4,
      url: goal.url ?? '',
      completionDate: goal.completionDate ?? null
    },
    actions: actions ??[]})
  },[actions, goal]);

  const currentStatusImage = useMemo(() => {
    const appliedStyle = goal?.status === 'completed' ? { mixBlendMode: 'luminosity'} : {};
    switch (goal?.motivationLevel) {
      case  2:
        return <SVGCakeStatus style={appliedStyle} />;
      case  4:
        return <SVGBucketStatus style={appliedStyle} />;
      case  6:
        return <SVGMeditateStatus style={appliedStyle} />;
      case  8:
        return <SVGCactusStatus style={appliedStyle} />;
      case  10:
        return <SVGMontainStatus style={appliedStyle} />;
      default:
        return <SVGMeditateStatus style={appliedStyle} />;
    }
  }, [goal?.motivationLevel, goal?.status]);
  const currentGoalStatus = useMemo(() => {
    switch (goal?.status) {
      case 'active':
        return (
          <GoalCardStatus status={goal?.status}> {t('todos_status_goal_on_it')}</GoalCardStatus>
        );
      case 'completed':
        return (
          <GoalCardStatus status={goal?.status}>{t('todos_status_goal_completed')} </GoalCardStatus>
        );
      case 'stuck':
        return (
          <GoalCardStatus status={goal?.status}>{t('todos_status_goal_stuck')} </GoalCardStatus>
        );
      case 'not-started':
        return (
          <GoalCardStatus status={goal?.status}>{t('todos_status_goal_not_started')} </GoalCardStatus>
        );
      default:
        return;

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal?.status]);
  const currentMotivationLevel = useMemo(() => {
    return <GoalCardStatus>
      {goal?.motivationLevel} / 10
    </GoalCardStatus>
  }, [goal?.motivationLevel]);
  const displayedDate = moment(goal?.completionDate).format('DD MMM');

  const handleDrawerVisibility = useCallback(() => {
    setDrawerVisible(!isDrawerVisible);
  }, [isDrawerVisible]);

  const handleSubmit = useCallback(async (values, initialValues) => {

    const newActions = values.actions.filter(action => !initialValues.actions.some(originalAction => _.isEqual(originalAction, action)));
    const hasGoalChanged = !_.isEqual(initialValues.goal, values.goal);
    if (goal.id && hasGoalChanged) {
      try {
        await updateGoal({
          id: goal.id,
          ...values.goal
        }).then((data) => {
          // onUpdateGoal(data.data);
        }
        ).finally(() => setDrawerVisible(false));

      } catch (error) {
        console.error('Error updating goal', error);
      }

    }
    if(newActions.length > 0) {
      const goalActionsPromises = newActions.map(async (action) => {
        if(action.id == null){
          return createUserAction({
           contextType: 'goal',
           contextId: goal.id,
           title: action.title,
           note: action.note,
           status: action.status,
           motivationLevel: action.motivationLevel,
           completionDate: action.completionDate
         }).then(async (actionResponse) => {
           await createUserGoalAction({
             actionId: actionResponse.data.data.id,
             goalId: goal.id,
             userId: user ? user.id : '',
           });
         }

         );
        } else {
          return updateUserAction({
            id: action.id,
            data: {
              contextType: action.contextType,
              contextId: action.contextId,
              title: action.title,
              note: action.note,
              status: action.status,
              motivationLevel: action.motivationLevel,
              completionDate: action.completionDate
            }
          })
        }



      });
      await Promise.allSettled(goalActionsPromises)

      setDrawerVisible(false)
    }
  }, [createUserAction, createUserGoalAction, goal.id, updateGoal, updateUserAction, user]);
  const handleDeleteGoalAction = useCallback(async(goalActionId) => {
    if(goalActionId != null){
      await deleteGoalAction({ userGoalActionId: goalActionId ?? null, goalId: goal.id });

    }
  }, [deleteGoalAction, goal.id]);

  const handleDelete = useCallback(async(e) => {
    e.stopPropagation();
    await deleteGoal({  id: goal.id}).then(() => onDelete(goal.id));
    e.preventDefault();
  }, [deleteGoal, goal.id, onDelete]);
  if (goal == null) {
    return <></>;
  }

  return (
    <>
      <GoalCardContainer highlightCommonGoal={highlightCommonGoal} hasMaxHeight={hasMaxHeight} className={'goal-container'} onClick={(e) =>  {
        e.preventDefault();
        e.stopPropagation();
        handleDrawerVisibility();
}} {...props}>
        <GoalIconCtaContainer>
          {currentStatusImage}
            <Popover content={
              <GoalPopoverContainer onClick={(e) =>  handleDelete(e)}>
              <Delete02Icon/>
              </GoalPopoverContainer>
            } title={t('delete_goal')}>
            <Menu />
          </Popover>
        </GoalIconCtaContainer>
        <GoalDescContainer isCompleted={goal?.status === 'completed'}>{goal?.title}</GoalDescContainer>
        <GoalCTAInfo>
          {currentGoalStatus}
          {displayedDate}
            {currentMotivationLevel}
        </GoalCTAInfo>
      </GoalCardContainer>
       <GoalDrawer
        isOpen={isDrawerVisible}
        onClose={() => setDrawerVisible(false)}
        children={
          <GoalCreateDrawerContent>
          <GoalDrawerClose onClick={() => setDrawerVisible(false)}>
            <CancelCircleIcon/>
          </GoalDrawerClose>
            <Formik
              initialValues={goalFormValues}
              onSubmit={(values) => handleSubmit(values, goalFormValues)}
              enableReinitialize={true} // This is crucial for your use case
          >
              {({ values,initialValues, isSubmitting, isValid , errors, submitForm}) => {
                return (<Form>
                  <GoalDrawerFormContent
                    goal={goal}
                    handleDeleteGoalAction={handleDeleteGoalAction}
                  />
                </Form>)
              }}
            </Formik>
          </GoalCreateDrawerContent>
        }
      />
      <GoalDrawerBckd visible={isDrawerVisible} />
    </>
  );
};

export default GoalCard;
