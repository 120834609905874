import { computed } from '@preact/signals-react';
import { Popover, Progress, Select } from 'antd/lib';
import { ArrowLeft01Icon, ArrowRight01Icon, BorderNone02Icon, CancelCircleIcon, Delete02Icon, PlusSignIcon, Sad02Icon } from 'hugeicons-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  useCreateUserActionMutation, useDeleteUserActionMutation, useGetUserActionsQuery, useGetUserGoalActionsQuery, useUpdateUserActionMutation
} from '../api/apiSlice';
import { ReactComponent as Menu } from '../images/more-2.svg';

import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Layout } from '../components/Layout';
import { Typography } from '../components/Typography';
import { ActionContainer, ActionListContainer, ActionStatusContainer, ActionTextContainer } from '../features/goal/goal-action/action.style';
import ActionDrawerFormContent from '../features/goal/goal-action/actionDrawerFormContent';
import SVGBucketStatus from '../features/goal/goal-icons/bucket';
import SVGCactusStatus from '../features/goal/goal-icons/cactus';
import SVGCakeStatus from '../features/goal/goal-icons/cake';
import SVGMeditateStatus from '../features/goal/goal-icons/meditate';
import SVGMontainStatus from '../features/goal/goal-icons/montain';
import GoalCard from '../features/goal/goalCard';
import { GoalCardStatus, GoalEmptyContainer, GoalPopoverContainer, LoadingGoalCard } from '../features/goal/goalCard.style';
import GoalDrawer from '../features/goal/goalDrawer';
import { GoalCreateDrawerContent, GoalDrawerBckd, GoalDrawerClose } from '../features/goal/goalDrawer.style';
import GoalDrawerCreate from '../features/goal/goalDrawerCreate';
import useLockTouchBodyScroll from '../hooks/useLockTouchBodyScroll';
import { isMobile } from '../signals/global.signals';
import { Button } from '../stories/Button';
import {
  ToDosActionContainer,
  ToDosGoalContainer,
  ToDosHeaderContainer,
  ToDosLayoutContainer
} from '../styles/pages/to-dos.style';
const { Option } = Select;

// ========================== LAYOUT ===============================
const PaginationContainer = styled.div`
  ${css`
  grid-column: 1 / -1;
  min-width: 240px;
  justify-self: center;

  display: grid;
  grid-template-columns: 0fr 1fr 0fr;
  place-items: end center;
  gap: 8px;
  .loading{
  border-radius: 8px;
      place-self: center;
      margin-bottom: 4px;
     .ant-progress-text{
      display: none!important;
      }
  }
  svg {
    cursor: pointer;
    margin-bottom: 10px;
  }
  .ant-progress {
    justify-self: normal;
    div:first-of-type {
      display: grid;
    }
    .ant-progress-text {
      position: relative;
      top: 4px;
      place-self: center;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Standard syntax */
    }
    .ant-progress-status-success .ant-progress-text{
        color: black;
    }
    .ant-progress-outer {
        padding: 0;
        margin: 0;
      border-radius: 4px;
      .ant-progress-inner {
        border-radius: 4px;

        background-color: #f5f5f5;

        .ant-progress-bg {
          border-radius: 4px;

          background-color: var(--accent-color);
          filter: drop-shadow(var(--accent-color) 1px 1px 4px);
        }
      }
    }
  }`}
`;
// ========================= COMPONENT =============================
const mobileState = computed(() => isMobile.value);

const INDEX_VALUE_INIT = 1;
const TOTAL_PAGE_VALUE_INIT = mobileState.value ? 4 : 6;
let PAGE_SIZE = mobileState.value ? 4 : 6;



const ToDos = ({ user }) => {
  const { t } = useTranslation();
  const [pageIndexUserGoalAction, setPageIndexUserGoalAction] = useState(INDEX_VALUE_INIT);
  // const [pageIndexUserGoal, setPageIndexUserGoal] = useState(INDEX_VALUE_INIT);
  const [pageIndexUserAction, setPageIndexUserAction] = useState(INDEX_VALUE_INIT);
  const [totalUserGoalActionsPages, setTotalUserGoalActionsPages] = useState(TOTAL_PAGE_VALUE_INIT);
  // const [totalUserGoalPages, setTotalUserGoalPages] = useState(TOTAL_PAGE_VALUE_INIT);
  const [totalUserActionsPages, setTotalUserActionsPages] = useState(TOTAL_PAGE_VALUE_INIT);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [userActions, setUserActions] = useState([]);
  const [userGoalActionsAndGoals, setUserGoalActionsAndGoals] = useState([]);
  const [isDrawerCreateGoalOpen, setDrawerCreateGoalOpen] = useState(false);
  const [isDrawerCreateActionOpen, setDrawerCreateActionOpen] = useState(false);
  const [openDrawerActionId, setOpenDrawerActionId] = useState(null);
  const [changedPageSizeCount, setChangedPageSizeCount] = useState(0);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [statusActionFilter, setStatusActionFilter] = useState(undefined);
  const [commonHoveredGoalId, setCommonHoveredGoalId] = useState(null);

  const className = 'goal-drawer';
  useLockTouchBodyScroll(isDrawerCreateGoalOpen || isDrawerCreateActionOpen || openDrawerActionId !== null,className);

  const {
    data: userGoalActionData,
    isLoading: isLoadingUserGoalActions,
    refetch ,
    isFetching : isFetchingUserGoalActions
  } = useGetUserGoalActionsQuery({
    status: statusFilter,
    pageIndex: pageIndexUserGoalAction,
    pageSize: pageSize
  });

  const {
    data: userActionData,
    isLoading: isLoadingUserActions,
    refetch: refetchUserActions,
    isFetching: isFetchingUserActions
  } = useGetUserActionsQuery({
    status: statusActionFilter,
    pageIndex: pageIndexUserAction,
    pageSize: 6
    });

  const [createUserAction] = useCreateUserActionMutation();
  const [updateUserAction] = useUpdateUserActionMutation();
  const [deleteUserAction] = useDeleteUserActionMutation();
  const openCreateGoalDrawer = useCallback(() => {
    setDrawerCreateGoalOpen(true);
  }, []);
  const openCreateActionDrawer = useCallback(() => {
    setDrawerCreateActionOpen(true);
  }, []);
  const openActionDrawer = (actionId) => {
    setOpenDrawerActionId(actionId);
  };
  const closeActionDrawer = useCallback(() => {
    setOpenDrawerActionId(null);
  }, []);
  const createActionCallback = useCallback(async (action) => {
    await createUserAction({
      title: action.title,
      note: action.note,
      userId: user.id,
      contextType: 'user',
      contextId: user.id,
      motivationLevel: action.motivationLevel,
      completionDate: action.completionDate,
      status: action.status
    }).then(() => closeActionDrawer());
  }, [createUserAction, user.id,closeActionDrawer]);
  const handleUpdateAction = useCallback(async(values) => {
    await updateUserAction({
      id: values.id,
      data: {
        contextType: values.contextType,
        contextId: values.contextId,
        title: values.title,
        note: values.note,
        status: values.status,
        motivationLevel: values.motivationLevel,
        completionDate: values.completionDate
      }
    }).then(() => closeActionDrawer());
  },[updateUserAction,closeActionDrawer ]);

  const getPreviousGoalActionPage = useCallback(() => {
    if (pageIndexUserGoalAction > 1) {
      setPageIndexUserGoalAction((prev) => prev - 1);
      refetch();
    }
  }, [pageIndexUserGoalAction, refetch]);

  const getNextUserGoalActionPage = useCallback(() => {
    if (pageIndexUserGoalAction < totalUserGoalActionsPages) {
      setPageIndexUserGoalAction((prev) => prev + 1);
      refetch();
    }
  }, [pageIndexUserGoalAction, refetch, totalUserGoalActionsPages]);


  const getPreviousActionPage = useCallback(() => {
    if (pageIndexUserAction > 1) {
      setPageIndexUserAction((prev) => prev - 1);
      refetchUserActions();
    }
  }, [pageIndexUserAction, refetchUserActions]);

  const getNextUserActionPage = useCallback(() => {
    if (pageIndexUserAction < totalUserActionsPages) {
      setPageIndexUserAction((prev) => prev + 1);
      refetchUserActions();
    }
  }, [pageIndexUserAction, refetchUserActions, totalUserActionsPages]);

  const handleDeleteAction = useCallback((e, actionId) => {
    e.stopPropagation();
    deleteUserAction({
      id: actionId
    }).then(() => {
      setUserActions(prev => {
        const updatedActions = prev.filter(action => action?.attributes?.action?.data?.id !== actionId);
        return updatedActions;
      });
    })

    e.preventDefault();

  }, []);
  const handleDeleteGoal = useCallback((goalId) => {
    setUserGoalActionsAndGoals(prev => {
      const updatedGoals = prev.filter(goalAction => goalAction?.attributes?.goal?.data?.id !== goalId);
      return updatedGoals});
  }, []);
  const handleUpdateGoal = useCallback((goal, newActions) => {
    setUserGoalActionsAndGoals(prev => {
      const updatedGoals = prev.map(goalAction => {
      if(goalAction?.attributes?.goal?.data.id === goal?.data.id){
        const oldActions = goalAction.attributes.actions;

        const hasChangedActions = oldActions && newActions && !isEqual(oldActions, newActions) ;
      if(hasChangedActions){
        const safeNewActions = Array.isArray(newActions) ? newActions : [];
        return { ...goalAction,
        attributes: {
        ...goalAction.attributes,
        goal: { ...goalAction.attributes.goal, data: goal?.data },
        actions: [...goalAction.attributes.actions, ...safeNewActions]
            }
          }
        }
      return { ...goalAction,
      attributes: {
      ...goalAction.attributes,
      goal: { ...goalAction.attributes.goal, data: goal?.data }
            }
          }
        }
      });
      return updatedGoals;
    });
  }, []);

  const handleCheckPageSize = useCallback((goalsLength) => {
    if(goalsLength > 6 && pageSize > PAGE_SIZE){
     setPageSize(PAGE_SIZE);
      refetch({
        pageSize: PAGE_SIZE
      });
   }
  }, [pageSize, refetch]);
  useEffect(() => {
    if (userGoalActionData != null && userActionData!=null) {
      let goalIds = new Set();
      let uniqueGoalActionsAndGoals = [];

      setTotalUserGoalActionsPages(userGoalActionData.meta?.pagination?.pageCount);
      setTotalUserActionsPages(userActionData.meta?.pagination?.pageCount);


      const filteredUserActions = userGoalActionData.data.filter(
        (goalActionData) =>
        {
          return  goalActionData?.attributes?.actions != null}
      );
      const processedUserActions = userActionData.data.map((actionData) => {
          return {
              attributes: {
                  actions: [{
                          id: actionData.id,
                          attributes: actionData.attributes

                  }],
                  goal: {
                      data: null
                  }
              }
          };
      });
      const combinedUserActions = [...filteredUserActions, ...processedUserActions].reduce((acc, current) => {

          const x = acc.length > 0 ? acc.find(item =>
           {
             return item.attributes.actions.some(action =>
             {
              return current.attributes.actions.some(currentAction => currentAction.id === action.id)}
           )  }
          ) : null;
          if (!x) {
              return acc.concat([current]);
          } else {
              return acc;
          }
      }, []);

      userGoalActionData.data.forEach((goalActiondata) => {
        const goal = goalActiondata?.attributes?.goal?.data;
        if (goal != null && !goalIds.has(goal.id)) {
          goalIds.add(goal.id);
          uniqueGoalActionsAndGoals.push(goalActiondata);
        } else if(changedPageSizeCount === 0 && goal != null && goalIds.has(goal.id)){
          setChangedPageSizeCount(p => p+1);
        }
      });

      const itemsCountToAdd = uniqueGoalActionsAndGoals.length >= 9 ? 0 : PAGE_SIZE - uniqueGoalActionsAndGoals.length ;
      if(changedPageSizeCount === 0 && itemsCountToAdd > 0 && pageIndexUserGoalAction < totalUserGoalActionsPages){
        setPageSize(p => p + itemsCountToAdd);
      }
      handleCheckPageSize(uniqueGoalActionsAndGoals.length);

      setUserActions(combinedUserActions);
      setUserGoalActionsAndGoals(uniqueGoalActionsAndGoals);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ userActionData, userGoalActionData]);
  useEffect(() => {
    setPageIndexUserGoalAction(INDEX_VALUE_INIT);
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter]);

  useEffect(() => {
    setPageIndexUserAction(INDEX_VALUE_INIT);
    refetchUserActions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusActionFilter]);
  return (
    <Layout title={'ToDos'} user={user} fluid withHeaderSticky changeOnMobile>
      <ToDosLayoutContainer>
      <Typography className='todos-title' fz={'26px'} fw={'500'} lh={'21px'} lt={'-0.03em'} mb='24px' color={'var(--accent-color)'}>
        {t('user_todos_list')}
      </Typography>
        <ToDosHeaderContainer>

          <Typography fz={'18px'} fw={'500'} lh={'21px'} lt={'-0.03em'} color={'var(--accent-color)'}>
            {t('user_goal_list')}
          </Typography>
            <Button icon={<PlusSignIcon size={20} color={'var(--accent-color)'}  />} onClick={openCreateGoalDrawer}>
                {isMobile.value ? '' : <Typography fz={'16px'} fw={'500'} color={'var(--accent-color)'} >{t('user_goal_add')}</Typography>}
          </Button>
          <Select
            placeholder={t('user_goal_status_all')}
            name="test"
            style={{ width: 'fit-content', minWidth: '96px' }}
            onChange={(value) => {
              setStatusFilter(value);
            }}>
            <Option value="completed">{t('user_goal_status_completed')}</Option>
            <Option value="active">{t('user_goal_status_on_it')}</Option>
            <Option value="stuck">{t('user_goal_status_stuck')}</Option>
            <Option value={undefined}>{t('user_goal_status_all')}</Option>
          </Select>
        </ToDosHeaderContainer>
        <ToDosGoalContainer>
          {
          isLoadingUserGoalActions || isFetchingUserGoalActions
            ? Array.from({ length: 6 }).map((_, index) => {
                return <LoadingGoalCard key={index} className="loading" />;
              })
            :  userGoalActionsAndGoals.map((userGoalAction, index) =>
              {

                return <GoalCard key={ userGoalAction?.attributes?.goal?.data?.id ?? index}
              userGoalActionId={userGoalAction?.id}
              hasMaxHeight={userGoalActionsAndGoals.length <= 6}
              actions={ userGoalAction?.attributes?.actions?.length > 0 ? userGoalAction?.attributes?.actions : null}
              goal={{...userGoalAction?.attributes?.goal?.data?.attributes, id: userGoalAction?.attributes?.goal?.data?.id}}
              onDelete={(goalId) =>  handleDeleteGoal(goalId)}
              onUpdateGoal={(goal) => handleUpdateGoal(goal)}
              onMouseOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCommonHoveredGoalId(userGoalAction?.attributes?.goal?.data?.id);
              }}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCommonHoveredGoalId(null); }}
              highlightCommonGoal={userGoalAction?.attributes?.goal?.data?.id === commonHoveredGoalId && userGoalAction?.attributes?.actions !== null}
              />}
              )}
          {!isFetchingUserGoalActions && userGoalActionsAndGoals.length === 0  && (
            <GoalEmptyContainer>
              <Sad02Icon size={80} />
              <Typography fz="24px" fw="500">
                No goal was found
              </Typography>
            </GoalEmptyContainer>
          )}
          {totalUserGoalActionsPages > 1 && (
            <PaginationContainer>
              <ArrowLeft01Icon
                onClick={getPreviousGoalActionPage}
                color={pageIndexUserGoalAction > 1 ? 'black' : 'grey'}
                style={{
                  pointerEvents: pageIndexUserGoalAction === 1 ? 'none' : 'unset',
                  cursor: pageIndexUserGoalAction === 1 ? 'not-allowed' : 'pointer'
                }}
              />
              <Progress
                className={isLoadingUserGoalActions || isFetchingUserGoalActions ? 'loading ' : ''}
                percent={(pageIndexUserGoalAction / totalUserGoalActionsPages) * 100}
                format={(percent) => `${pageIndexUserGoalAction} / ${totalUserGoalActionsPages}`}
                type="line"
                status={
                  pageIndexUserGoalAction > 1 && pageIndexUserGoalAction < totalUserGoalActionsPages
                    ? 'active'
                    : 'success'
                }
              />
              <ArrowRight01Icon
                onClick={getNextUserGoalActionPage}
                color={pageIndexUserGoalAction !== totalUserGoalActionsPages ? 'black' : 'grey'}
                style={{
                  pointerEvents:
                    pageIndexUserGoalAction === totalUserGoalActionsPages ? 'none' : 'unset',
                  cursor:
                    pageIndexUserGoalAction === totalUserGoalActionsPages
                      ? 'not-allowed'
                      : 'pointer'
                }}
              />
            </PaginationContainer>
          )}
        </ToDosGoalContainer>
        <ToDosActionContainer>
          <Typography fz={'18px'} fw={'500'} lh={'21px'} lt={'-0.03em'} color={'var(--accent-color)'}>
            {t('user_action_list')}
          </Typography>
            <Button icon={<PlusSignIcon size={20} color={'var(--accent-color)'}  />} onClick={openCreateActionDrawer}>
                {isMobile.value ? '' : <Typography fz={'16px'} fw={'500'} color={'var(--accent-color)'} >{t('user_action_add')}</Typography>}
          </Button>
            <Select
              placeholder={t('user_goal_status_all')}
              name="test"
              style={{ width: 'fit-content', minWidth: '96px' }}
              onChange={(value) => {
                setStatusActionFilter(value);
              }}>
              <Option value="completed">{t('user_goal_status_completed')}</Option>
              <Option value="active">{t('user_goal_status_on_it')}</Option>
              <Option value="stuck">{t('user_goal_status_stuck')}</Option>
              <Option value={undefined}>{t('user_goal_status_all')}</Option>
            </Select>
        </ToDosActionContainer>
         <ActionListContainer> {
           isLoadingUserActions || isFetchingUserActions
             ? Array.from({ length: 6 }).map((_, index) => {
                 return <LoadingGoalCard key={index} className="loading" style={{maxWidth: '100%'}} />;
               })
             :  userActions.map((actionsData) =>
               (actionsData.attributes.actions.map((action, index) =>
               {
               const actionFormValues =
               {
                 id: action?.id ?? null,
                 contextType: action?.attributes?.contextType ?? 'goal' ,
                 contextId: action?.attributes?.contextId ?? 0,
                 title: action?.attributes?.title ?? '',
                 note: action?.attributes?.note ?? '',
                 status: action?.attributes?.status ?? 'active',
                 motivationLevel: action?.attributes?.motivationLevel ?? 4,
                 completionDate: action?.attributes?.completionDate ?? null
               }
               ;
            const actionStatus = () => {
              const status = action?.attributes?.status ?? 'active';

              switch (status) {
                case 'active':
                  return (
                    <GoalCardStatus status={status}> {t('todos_status_goal_on_it')}</GoalCardStatus>
                  );
                case 'completed':
                  return (
                    <GoalCardStatus status={status}>{t('todos_status_goal_completed')} </GoalCardStatus>
                  );
                case 'stuck':
                  return (
                    <GoalCardStatus status={status}>{t('todos_status_goal_stuck')} </GoalCardStatus>
                  );
                case 'not-started':
                  return (
                    <GoalCardStatus status={status}>{t('todos_status_goal_not_started')} </GoalCardStatus>
                  );
                default:
                  return;

              }

            }
            const difficultyGoal = () => {
              const difficulty = action?.attributes?.motivationLevel ?? actionsData?.attributes?.goal?.data?.attributes?.motivationLevel;
              switch(difficulty) {
                case 2:
                  return { svg: <SVGCakeStatus />, label: 'Cake', motivationLevel: 2};
                case 4 :
                  return {svg : <SVGBucketStatus /> , label : 'Bucket',motivationLevel: 4};
                case (5 || 6) :
                  return {svg : <SVGMeditateStatus /> , label : 'Meditate',motivationLevel: 6};
                case 8:
                  return {svg : <SVGCactusStatus /> , label : 'Cactus',motivationLevel: 8};
                case 10:
                  return {svg : <SVGMontainStatus /> , label : 'Montain',motivationLevel: 10};
                default:
                  return { svg: <BorderNone02Icon /> , label : 'Meditate',motivationLevel: 6};
              }
            }

            const actionId = action?.id;
            const goalId = actionsData?.attributes?.goal?.data?.id;
            const displayedDate = moment(action?.attributes?.completionDate).format('DD MMMM');
            return <ActionContainer key={index}
              onClick={(e) => {
                e.stopPropagation();

                openActionDrawer(actionId);
                e.preventDefault()
              }}

            //   onMouseOver={(e) => {
            //     e.stopPropagation();
            //     e.preventDefault();
            //   setCommonHoveredGoalId(goalId);

            // }}
            //   onMouseLeave={(e) => {
            //     e.stopPropagation();
            //     e.preventDefault();
            //     setCommonHoveredGoalId(null);
            //     }}
              highlightCommonGoal={goalId && goalId === commonHoveredGoalId}
              className={goalId != null && isFetchingUserGoalActions ? 'loading' : ''}
            >
                  <ActionTextContainer>
                  <Typography color="black" fz={'16px'} fw={'450'} ellipsis lineClamp="2" h="36px">
                    { action?.attributes?.title}
                  </Typography>
                    <ActionStatusContainer>
                      {actionStatus()}
                        {difficultyGoal().svg}
                        {displayedDate}
                    </ActionStatusContainer>
                  </ActionTextContainer>
              <Popover  onMouseOver={(e) => {
                e.stopPropagation();
                e.preventDefault();

            }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                e.preventDefault();
                }} content={
                  <GoalPopoverContainer onClick={(e) =>  handleDeleteAction(e, actionId)}>
                  <Delete02Icon/>
                  </GoalPopoverContainer>
                } title={t('delete_action')}>
                  <Menu/>
              </Popover>

              <GoalDrawer
                isOpen={openDrawerActionId === actionId}
                onClose={(e) =>{
                 e.stopPropagation();
                  closeActionDrawer();
                  }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

              }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  }}
                children={
                  <GoalCreateDrawerContent >
                  <GoalDrawerClose
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                  }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      closeActionDrawer();
}}>
                    <CancelCircleIcon/>
                  </GoalDrawerClose>
                    <Formik
                      initialValues={actionFormValues}
                      onSubmit={(values) => handleUpdateAction(values)}
                      enableReinitialize={true} // This is crucial for your use case
                  >
                      {({ values,initialValues, isSubmitting, isValid , errors, submitForm}) => {
                        return (<Form>
                          <ActionDrawerFormContent
                            action={action}
                            goalId={goalId}
                          />
                        </Form>)
                      }}
                    </Formik>
                  </GoalCreateDrawerContent>
                }
              />
              <GoalDrawerBckd visible={openDrawerActionId === actionId} />

            </ActionContainer>
          })
             )
          )
        }
          {!isFetchingUserActions && !isFetchingUserGoalActions && userActions.length === 0  && (
            <GoalEmptyContainer>
              <Sad02Icon size={80} />
              <Typography fz="24px" fw="500">
                No actions was found
              </Typography>
            </GoalEmptyContainer>
          )}
          {totalUserActionsPages > 1 && (
            <PaginationContainer>
              <ArrowLeft01Icon
                onClick={getPreviousActionPage}
                color={pageIndexUserAction > 1 ? 'black' : 'grey'}
                style={{
                  pointerEvents: pageIndexUserAction === 1 ? 'none' : 'unset',
                  cursor: pageIndexUserAction === 1 ? 'not-allowed' : 'pointer'
                }}
              />
              <Progress
                className={isLoadingUserActions || isFetchingUserActions ? 'loading ' : ''}
                percent={(pageIndexUserAction / totalUserActionsPages) * 100}
                format={(percent) => `${pageIndexUserAction} / ${totalUserActionsPages}`}
                type="line"
                status={
                  pageIndexUserAction > 1 && pageIndexUserAction < totalUserActionsPages
                    ? 'active'
                    : 'success'
                }
              />
              <ArrowRight01Icon
                onClick={getNextUserActionPage}
                color={pageIndexUserAction !== totalUserActionsPages ? 'black' : 'grey'}
                style={{
                  pointerEvents:
                    pageIndexUserAction === totalUserActionsPages ? 'none' : 'unset',
                  cursor:
                    pageIndexUserAction === totalUserActionsPages
                      ? 'not-allowed'
                      : 'pointer'
                }}
              />
            </PaginationContainer>
          )}
        </ActionListContainer>
        <GoalDrawer
          isOpen={isDrawerCreateActionOpen}
          onClose={() => setDrawerCreateActionOpen(false)}
          children={
            <GoalCreateDrawerContent>
            <GoalDrawerClose onClick={() => setDrawerCreateActionOpen(false)}>
              <CancelCircleIcon/>
            </GoalDrawerClose>
              <Formik
                initialValues={{
                  contextType:  'goal' ,
                  contextId:  0,
                  title:  '',
                  note:  '',
                  status:  'not-started',
                  motivationLevel: 4,
                  completionDate: null
                }}
                onSubmit={(values) => createActionCallback(values)}
                enableReinitialize={true} // This is crucial for your use case
            >
                {({ values,initialValues, isSubmitting, isValid , errors, submitForm}) => {

                  return (<Form style={{marginTop: '32px'}}>
                    <ActionDrawerFormContent
                      action={null}
                    />
                  </Form>)
                }}
              </Formik>
            </GoalCreateDrawerContent>
          }
        />
        <GoalDrawerBckd visible={isDrawerCreateActionOpen} />

      </ToDosLayoutContainer>
      <GoalDrawerCreate user={user}  isDrawerVisible={isDrawerCreateGoalOpen} setDrawerVisible={ setDrawerCreateGoalOpen} />
    </Layout>
  );
};

export default ToDos;
