import { Field, useFormikContext } from "formik";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { TextAreaField } from "../../../components/Form/styles";
import { Typography } from "../../../components/Typography";
import GoalDate from "../goalDate";
import GoalDifficulty from "../goalDifficulty";
import GoalStatus from "../goalStatus";
import { ActionFormContentContainer, ActionFormDifficultyStatusContainer } from "./action.style";

const ActionDrawerFormContent = ({action, goalId}) => {
  const { t } = useTranslation();

  const { isSubmitting, isValid , submitForm} = useFormikContext();

  return <ActionFormContentContainer>
  <ActionFormDifficultyStatusContainer>
    <Field as={GoalStatus} initialStatus={action?.attributes?.status} goalId={action?.id} name={'status'} />
    <Field as={GoalDifficulty} motivationLevel={action?.attributes?.motivationLevel} goalId={action?.id} name={'motivationLevel'} />
    <Field as={GoalDate}  goalDate={ action?.attributes?.completionDate} name={"completionDate"} />
  </ActionFormDifficultyStatusContainer>

  <Typography fz="16px" fw="500" mt="24px">
    {t('action_name_placeholder')}
  </Typography>
  <TextAreaField
    component="textarea"
    type="text"
    name="title"
    autoComplete="title"
    className="goal-field-title"
    placeholder={t('action_name_placeholder')}
  />
    <Typography fz="16px" fw="500">
      {t('action_note_placeholder')}
    </Typography>
  <TextAreaField
    component="textarea"
    type="text"
    name="note"
    autoComplete="note"
    className="goal-field-note"
    placeholder={t('action_note_placeholder')}
  />
    <Button
    onClick={() => submitForm()}
    type="submit"
    size="large"
    disabled={isSubmitting || !isValid}
    style={{
      justifySelf:'center',
      display:'grid',
      justifyContent: 'center',
      width: '100%',
      marginTop: '24px',
      textTransform: 'uppercase',
      color: '#fff',
    }}
       > {t('save_selection')}</Button>
  </ActionFormContentContainer>;
}

export default ActionDrawerFormContent;
