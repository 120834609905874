import { Form, Formik } from "formik";
import { CancelCircleIcon } from "hugeicons-react";
import { useCallback, useMemo, useState } from "react";
import { useCreateGoalMutation, useCreateUserActionMutation, useCreateUserGoalActionsMutation } from "../../api/apiSlice";
import GoalDrawer from "./goalDrawer";
import { GoalCreateDrawerContent, GoalDrawerBckd, GoalDrawerClose } from "./goalDrawer.style";
import GoalDrawerFormContent from "./goalDrawerFormContent";





const GoalDrawerCreate = ( {user, goal, isDrawerVisible, setDrawerVisible}) => {
  const [createUserGoal] = useCreateGoalMutation();
  const [createUserGoalActions] = useCreateUserGoalActionsMutation();
  const [createUserAction] = useCreateUserActionMutation();

const [isOpen, setIsOpen] = useState()
  const goalFormValues = useMemo(() => {
    return {
    goal: {
      title:  goal?.title ??'',
      description:  goal?.description ??'',
      status:  goal?.status ??'not-started',
      motivationLevel: goal?.motivationLevel ?? 4,
      url: goal?.url ?? ''
    },
    actions: [
      {
        contextType: 'goal',
        contextId: goal?.id != null ? Number(goal?.id) : 0,
        title: '',
        note: '',
        status: 'not-started'
      }
    ]}

  },[goal?.description, goal?.id, goal?.motivationLevel, goal?.status, goal?.title, goal?.url]);

  const handleSubmit = useCallback(async (values) => {

    const goalResponse = await createUserGoal({
      title: values.goal.title,
      description: values.goal.description,
      userId: user ? user.id : '',
      motivationLevel: values.goal.motivationLevel,
      status: values.goal.status,
      completionDate: values.goal.completionDate
    });
    const actionPromises = values.actions.map(async (action) => {
      const response = await createUserAction({
        contextType: 'goal',
        contextId: goalResponse.id ,
        title: action.title,
        note: action.note,
        status: action.status,
        completionDate: action.completionDate,
        motivationLevel: action.motivationLevel
      });
      action.id = response.id;
    });

    await Promise.allSettled(actionPromises);


    if (values.actions.length > 0 ) {

      const goalActionPromises = values.actions.map((action) => {
        return createUserGoalActions({
          actionId: action.id,
          goalId: goalResponse.id,
          userId: user ? user.id : ''
        });
      });

      await Promise.allSettled(goalActionPromises).finally(() => setDrawerVisible(false));
    }
  }, [createUserAction, createUserGoal, createUserGoalActions, setDrawerVisible, user]);

  return <>
  <GoalDrawer
    isOpen={isDrawerVisible}
    onClose={() => setDrawerVisible(false)}
    children={
      <GoalCreateDrawerContent>
      <GoalDrawerClose onClick={() => setDrawerVisible(false)}>
        <CancelCircleIcon/>
      </GoalDrawerClose>
        <Formik
          initialValues={goalFormValues}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true} // This is crucial for your use case
      >
             <Form>
              <GoalDrawerFormContent
                goal={null}
              />
            </Form>
        </Formik>
      </GoalCreateDrawerContent>
    }
  />
  <GoalDrawerBckd visible={isDrawerVisible} />

  </>;
}

export default GoalDrawerCreate;
